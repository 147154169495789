<template>
    <el-select v-model="currency" class="w-20 ml-3" @change="currencyChanged">
        <el-option value="" label="ALL" />
        <el-option value="sek" label="SEK" />
        <el-option value="eur" label="EUR" />
        <el-option value="usd" label="USD" />
        <el-option value="gbp" label="GBP" />
        <el-option value="nok" label="NOK" />
        <el-option value="dkk" label="DKK" />
        <el-option value="aud" label="AUD" />
        <el-option value="inr" label="INR" />
    </el-select>
</template>
<script>
import queryString from "query-string";
export default {
    data() {
        return {
            currency: "sek",
        };
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { currency } = queryString.parse(location.search);
            this.currency = currency || this.currency;
        },

        currencyChanged() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.currency = this.currency;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("change");
        },
    },
};
</script>
